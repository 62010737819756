import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import Button from "../../../components/button";
import { ReactComponent as ArrowRight } from "../../../assets/icons/arrow_right.svg";
import { ReactComponent as StarFillIcon } from "../../../assets/icons/star_fill.svg";
import { ReactComponent as StarIcon } from "../../../assets/icons/star.svg";
import CustomTextArea from "../CustomTextArea";
import MessageContract from "../../../MessageContract";

export const BaseUrl = process.env.REACT_APP_HOST_URL;
export const CmsUrl = process.env.REACT_APP_CMS_APP_URL;

function AddReview(props) {
  const {
    id,
    content,
    token,
    isLoading,
    onRefreshRating,
    onReactNativeMessageSend,
    onClose,
  } = props;

  console.log("AddReview", props);

  const [rate, setRate] = useState(5);
  const [review, setReview] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const submitReview = () => {
    const data = {
      content_type: content.content_type,
      id_cms_content: id, //Can be Parent Id_CMS_Content or Individual WidgetList Item id
      parent_id_cms_content: content.id_cms_content, //Is Parent Id_CMS_Content
      rating: rate,
      comment_list: [
        {
          by: "APP",
          text: review,
        },
      ],
    };

    console.log("Submit Data", data);

    const headers =
      !token || token == null
        ? {}
        : {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          };

    fetch(`${BaseUrl}/nj/jyot/app/analytics/user_content_rating/rating`, {
      method: "post",
      headers,
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("Data:", data);
        if (data?.status !== "SUCCESS") {
          setErrorMessage(data?.item.message || "");
        } else {
          onReactNativeMessageSend(
            MessageContract.AppRequest(MessageContract.TypeEnum.REFRESH, {
              id: id,
            })
          );
          onRefreshRating();
          onClose();
        }
      })
      .catch((err) => {
        // console.log(err.message);
        setErrorMessage(err.message);
      });
  };

  console.log("Content - content, review", content, review);

  return isLoading ? (
    <div className="h-screen flex items-center justify-center text-center text-2xl font-semibold">
      <div
        className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
        role="status"
      ></div>
      <span className="ml-5">Please wait, submitting your review...</span>
    </div>
  ) : (
    <div className="max-w-[500px] w-full px-4">
      <div className="flex flex-row justify-between text-center items-center">
        <div className="ml-2 font-primary-light text-center text-lg tracking-wider ">
          WRITE REVIEW
        </div>
        <div>
          <button
            type="button"
            class="bg-white rounded-full p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
            onClick={onClose}
          >
            <svg
              class="h-6 w-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
      </div>

      <div className="justify-center items-center"></div>
      <div className="flex flex-col h-[calc(100vh-110px)] justify-between">
        <div className="flex flex-row">
          <div className="shadow-md">
            <div className="w-12 h-16 rounded-md overflow-hidden">
              <img
                src={content.thumbnail_image_url}
                className="w-full h-full"
              />
            </div>
          </div>
          <div className="ml-2 font-primary-bold m-auto">{content.title}</div>
        </div>
        <div>
          <div className="mt-6 text-slate-400 text-sm w-fit">Rate</div>
          <div className="flex flex-row items-center pt-2">
            {[1, 2, 3, 4, 5].map((rt, key) => {
              return (
                <div key={key} onClick={() => setRate(rt)} className="pr-4">
                  {rate >= rt ? <StarFillIcon /> : <StarIcon />}
                </div>
              );
            })}
          </div>
        </div>
        <div>
          <div className="mt-10 mb-2 text-slate-400 text-sm w-fit">
            Write your review
          </div>
          <div className="mb-6 text-left text-sm flex w-full">
            <CustomTextArea
              value={review}
              rows={5}
              maxRows={10}
              onChange={(value) => setReview(value)}
            />
          </div>
        </div>
        <div className="mt-4  sticky bottom-0">
          <div className=" text-orange-400">{errorMessage}</div>
          <Button
            disabled={isLoading}
            onClick={() => {
              submitReview();
            }}
            title={"ADD REVIEW"}
            icon={<ArrowRight fill="none" stroke="orange" />}
          />
        </div>
        <div className="flex-1 h-40"></div>
      </div>
    </div>
  );
}

export default AddReview;
