import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Icons from "../../components/Icons";
import Moment from "react-moment";
import moment from "moment";
import HeaderSection from "../header";

export const BaseUrl = process.env.REACT_APP_HOST_URL;

function EventDetails(props) {
  const {
    id,
    token,
    content,
    isLoading,
    messageData,
    onRectNativeMessageSend,
  } = props;

  console.log("EventDetails - props", props);

  const RowWidget = ({ icon, title, value }) => {
    return (
      <div className="flex flex-row my-1">
        <div className="flex flex-2 whitespace-pre-wrap">
          <img src={icon} className="h-4 w-4 mt-0.5" alt="logo" />
          <p className="font-primary-regular text-sm text-black ml-2">
            {title}
          </p>
        </div>
        {value ? (
          <div className="flex flex-3 items-center">
            <p className="font-primary-regular text-sm text-black ml-2">
              {value}
            </p>
          </div>
        ) : (
          <></>
        )}
      </div>
    );
  };

  const AgendaDetails = () => {
    const proceed =
      content?.widget_list[0]?.agenda_details &&
      content?.widget_list[0]?.agenda_details.length > 0;
    console.log("AgendaDetails", content?.widget_list[0]?.agenda_details);
    const timeAgenda = [];
    if (proceed) {
      content?.widget_list[0]?.agenda_details.map((agendaItem, key) => {
        console.log("agendaItem", agendaItem);
        timeAgenda.push(
          <>
            <RowWidget
              icon={Icons.CalendarIcon}
              title={<Moment format="dddd">{agendaItem.agenda_date}</Moment>}
              value={
                <div className="flex flex-row my-1">
                  <div className="flex w-5">
                    <img src={Icons.LocationIcon} className="h-4 w-4 mt-0.5" />
                  </div>
                  <div className="flex  whitespace-nowrap ">
                    <b>{agendaItem.agenda_location}</b>&nbsp;on&nbsp;
                    <Moment format="YYYY-MM-DD">
                      {agendaItem.agenda_date}
                    </Moment>
                  </div>
                </div>
              }
            />
            <RowWidget
              icon={Icons.ClockIcon}
              title={`${agendaItem.agenda_start_time}\n${agendaItem.agenda_end_time}`}
              value={agendaItem.agenda_title}
            />
            <hr className="my-4"></hr>
          </>
        );
      });
    }
    return timeAgenda;
  };

  return isLoading ? (
    <div className="h-screen flex items-center justify-center text-center text-2xl font-semibold">
      <div
        className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
        role="status"
      ></div>
      <span className="ml-5">Please wait, loading...</span>
    </div>
  ) : content && content?.widget_list && content?.widget_list.length > 0 ? (
    <div className=" items-center w-full px-2 md:w-2/3 md:p-0">
      <HeaderSection {...props} />
      <div className="bg-primary-50 rounded-lg px-3 py-1 w-fit mt-5">
        <p className="text-black font-primary-regular text-[10px] tracking-widest uppercase">
          {moment(content.widget_list[0]?.agenda_date).isSame(new Date(), "day")
            ? "Present"
            : moment(content.widget_list[0]?.agenda_date).isAfter(
                new Date(),
                "day"
              )
            ? "Upcoming"
            : "Past"}
        </p>
      </div>
      {/* <div className="mt-2 flex items-center" onClick={() => {}}>
        <p className="font-primary-medium text-sm text-primary uppercase cursor-pointer">
          Add to calendar
        </p>
        <span class="material-symbols-outlined text-primary">
          navigate_next
        </span>
      </div> */}
      <div className="mt-6">
        <AgendaDetails />
      </div>
      <div>
        <p className="font-primary-medium text-base tracking-widest uppercase">
          Details
        </p>
        <p className="font-primary-regular text-base mt-1 ">
          {content.description}
        </p>
      </div>
    </div>
  ) : (
    <>
      <div className="h-screen flex items-center justify-center text-center text-2xl font-semibold">
        Oops sorry !
        <br />
        <br />
        <br />
        Unable to fetch Event, please try again...
      </div>
    </>
  );
}

export default EventDetails;
